import React from 'react';

import { Flex } from 'rebass';

import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Flex flexDirection="column">
    <SEO title="404: Not found" />
    <h1>PÁGINA NÃO ENCONTRADA</h1>
    <p>Esta rota não existe :(</p>
  </Flex>
);

export default NotFoundPage;
